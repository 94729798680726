.sp-land-overall-icons {
  background-image: url(../img/land-overall-icons.png);
}


.sp-land-overall-icons__arrow-1 {
  background-position: -124px -220px;
  width: 89px;
  height: 23px;
}
.sp-land-overall-icons__arrow-2 {
  background-position: -124px -186px;
  width: 94px;
  height: 34px;
}
.sp-land-overall-icons__arrow-3 {
  background-position: -248px 0px;
  width: 93px;
  height: 32px;
}
.sp-land-overall-icons__doc-1 {
  background-position: -300px -136px;
  width: 35px;
  height: 50px;
}
.sp-land-overall-icons__doc-2 {
  background-position: -300px -84px;
  width: 35px;
  height: 50px;
}
.sp-land-overall-icons__doc-3 {
  background-position: 0px -248px;
  width: 43px;
  height: 57px;
}
.sp-land-overall-icons__doc-4 {
  background-position: -300px -188px;
  width: 35px;
  height: 50px;
}
.sp-land-overall-icons__doc-5 {
  background-position: -43px -248px;
  width: 35px;
  height: 50px;
}
.sp-land-overall-icons__doc-6 {
  background-position: -300px -32px;
  width: 35px;
  height: 50px;
}
.sp-land-overall-icons__es {
  background-position: 0px -186px;
  width: 124px;
  height: 62px;
}
.sp-land-overall-icons__qiwi {
  background-position: 0px 0px;
  width: 124px;
  height: 62px;
}
.sp-land-overall-icons__sms {
  background-position: -124px -124px;
  width: 124px;
  height: 62px;
}
.sp-land-overall-icons__svyaz {
  background-position: 0px -124px;
  width: 124px;
  height: 62px;
}
.sp-land-overall-icons__t-blue {
  background-position: -248px -32px;
  width: 52px;
  height: 52px;
}
.sp-land-overall-icons__t-dark {
  background-position: -248px -188px;
  width: 52px;
  height: 52px;
}
.sp-land-overall-icons__t-white {
  background-position: -248px -136px;
  width: 52px;
  height: 52px;
}
.sp-land-overall-icons__tail {
  background-position: -248px -84px;
  width: 52px;
  height: 52px;
}
.sp-land-overall-icons__visa {
  background-position: -124px -62px;
  width: 124px;
  height: 62px;
}
.sp-land-overall-icons__wm {
  background-position: -124px 0px;
  width: 124px;
  height: 62px;
}
.sp-land-overall-icons__yad {
  background-position: 0px -62px;
  width: 124px;
  height: 62px;
}
